<template>
  <div>
    <Modal :options="{width: '80vw',close: true}" @close="$emit('close')">
      <div class="title">Solicitud</div>
      <div class="body">
        <Visualizador :solicitud_id="solicitud_id" :tipo_visualizacion="'modal'" />
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button v-if="solicitud.estatus == 'procesado'" class="btn btn-success mr-2" @click="aprobar_solicitud">Aprobar</button>
            <button v-if="solicitud.estatus == 'revisado'" class="btn btn-warning mr-2" @click="ocultar_solicitud">Ocultar</button>
            <button v-if="solicitud.estatus == 'procesado'" class="btn btn-warning mr-2" @click="ver_comiteviejo=true">Aprobar por comité viejo</button>
            <button v-if="['procesado','error_procesado'].indexOf(solicitud.estatus) != -1" class="btn btn-primary mr-2" @click="reiniciar_solicitud">Volver a procesar</button>
            <button v-if="solicitud.estatus == 'resolucion'" class="btn btn-primary mr-2" @click="solicitar_minuta">Solicitar minuta</button>
            <button v-if="['votacion','preparado_resolucion','resolucion'].indexOf(solicitud.estatus) != -1" class="btn btn-info mr-2" @click="ver_votos=true">Ver votos</button>
            <button class="btn btn-secondary" @click="$emit('close')">Cerrar</button>
          </div>
        </div>
      </div>
    </Modal>

    <Observacion v-if="ver_votos" :solicitud="solicitud" @update="cerrar_observaciones" @close="cerrar_observaciones" />
    <ComiteViejo v-if="ver_comiteviejo" :solicitud="solicitud" @close="ver_comiteviejo=false" @update="cerrar_comite_viejo"/>
  </div>
</template>

<script>
import Modal from '@/components/Modal';
import Visualizador from '@/apps/analyticpoint/pages/Solicitud/Visualizador';

import Observacion from '../../Solicitud/Observacion';
import ComiteViejo from './Info/ComiteViejo';

import api from '@/apps/analyticpoint/api/comite/solicitudes';
import apiCatalogo from '@/apps/analyticpoint/api/comite/catalogos';

export default {
  components: {
    Modal, Visualizador, Observacion, ComiteViejo
  },
  props: {
    solicitud_id: {
      type: String
    }
  },
  data() {
    return {
      solicitud: {
        estatus: 'procesado',
        votos: []
      },
      catalogo: {
        opciones: []
      },
      ver_votos: false,
      borrar_voto: null,
      ver_comiteviejo: false
    }
  },
  mounted() {
    this.cargar_solicitud();
  },
  methods: {
    async cargar_solicitud() {
      try {
        let url = this.solicitud_id+'?columns=id,solicitud_id,estatus';
        this.solicitud = (await api.obtener_solicitud(url)).data;
        this.catalogo = (await apiCatalogo.buscar_catalogo('razones_rechazo')).data;
      }catch(e) {
        this.$log.error('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    },
    async aprobar_solicitud() {
      try {
        let res = (await api.actualizar_solicitud(this.solicitud_id, {
          estatus: 'revisado'
        }));
        this.$log.info('res',res);
        this.$emit('update');
      }catch(e) {
        this.$log.error('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    },
    async ocultar_solicitud() {
      try {
        let res = (await api.actualizar_solicitud(this.solicitud_id, {
          estatus: 'procesado'
        }));
        this.$log.info('res',res);
        this.$emit('update');
      }catch(e) {
        this.$log.error('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    },
    async reiniciar_solicitud() {
      try {
        let res = (await api.reiniciar_solicitud(this.solicitud_id));
        this.$log.info('res',res);
        this.$emit('update');
      }catch(e) {
        this.$log.error('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    },
    verificar_comentario(voto) {
      let comentario = voto.comentario ? voto.comentario : '--';

      if (voto.acreditador.tipo == 'preponderante' && voto.resolucion == 'rechazado')
        for(let i=0; i<this.catalogo.opciones.length; i++) {
          if (this.catalogo.opciones[i].valor == voto.comentario) {
            comentario = this.catalogo.opciones[i].nombre;
            i=this.catalogo.opciones.length;
          }
        }

      return comentario;
    },
    tipo_voto(voto) {
      return voto.resolucion == 'aceptado' ? ' voto_success' : ' voto_refused';
    },
    async solicitar_minuta() {
      try {
        let res = (await api.generar_minuta(this.solicitud_id)).data;

        this.$helper.showMessage('Exito!','Se ha solicitado la generación de la minuta, te llegará por correo','succes','alert');
      }catch(e) {
        this.$log.error('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    },
    cerrar_observaciones(actualizar) {
      this.ver_votos = false;

      if (actualizar !== undefined)
        this.cargar_solicitud();
    },
    cerrar_comite_viejo(update) {
      this.ver_comiteviejo = false;

      if (update !== undefined)
        this.$emit('update',true);
    }
  }
}
</script>

<style lang="scss" scoped>
$solicitud_votada: #c0e9c7;
$solicitud_rechazada: #e2bcbc;
$porcentaje_dark: 30%;

.voto {

  .acreditador {
    .fecha {
      i {
        cursor: pointer;
      }
    }
  }
  .info_voto {
    div.resolucion {
      span.resolucion {
        width: 50%;
        font-weight: bold;
      }
    }
  }
}
.voto_success {
  .acreditador {
    background-color: $solicitud_votada;
  }

  .info_voto {
    div.resolucion {
      span.resolucion {
        color: darken($solicitud_votada, $porcentaje_dark);
      }
    }
  }
}

.voto_refused {
  .acreditador {
    background-color: $solicitud_rechazada;
  }

  .info_voto {
    div.resolucion {
      span.resolucion {
        color: darken($solicitud_rechazada, $porcentaje_dark);
      }
    }
  }
}
</style>